@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

body {
  font-size: 0.875rem;
  font-family: "DM Sans";
}

/* rolling schedule */
.rolling-schedule-th {
  table-layout: fixed;
  width: 14%;
}

.workout-item-wrapper i {
  width: 100%;
  height: 100%;
}

/* dashboard class controls */
.control-group {
  margin: 0.5rem 0 1.5rem;
  width: 25%;
  padding: 0.5rem;
  border-bottom: 1px solid lightgray;
  /* box-shadow: 2px 0px 13px -10px gray; */
}

.control-group > h3 {
  margin-bottom: 1.25rem;
}

.automode-subtext {
  color: gray;
  display: inline-block;
  margin: 0.55rem 0;
  font-size: 11pt;
}

.automode-subtext.hide {
  opacity: 0;
}

/* auto mode control container */
.switch-container {
  position: relative;
  display: inline-block;
  width: 216px;
  height: 80px;
  border-radius: 24px;
  text-align: center;
  margin-bottom: 0.5rem;
}

span.control-status {
  font-size: 18pt;
  font-weight: 700;
  color: #111;
  letter-spacing: 2px;
}

 /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 216px;
  height: 40px;
  margin-bottom: 0.25rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 32px;
  width: 32px;
  left: 4px;
  bottom: 4px;
  border: 2px solid lightgray;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #28a745;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(176px);
  -ms-transform: translateX(176px);
  transform: translateX(176px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
} 

/* content status */

.content-status-container .fas {
  font-size: 1.25rem;
}

.content-status-container p {
  font-size: 1.00rem;
}

/* dashboard class schedule */

table {
  width: 100%;
}

table td {
  overflow: hidden;
  white-space: nowrap;
}

table td .form-control {
  width: 100%;
  box-sizing: border-box;
}

table.dashboard-schedule {
  table-layout: fixed;
}

.dashboard-schedule tr {
  background-color: transparent;
}

.dashboard-schedule th {
  text-align: center;
}

.dashboard-schedule td {
  text-align: center;
}

.dashboard-schedule td:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.dashboard-schedule td>div {
  height: 2rem;
  align-content: center;
  position: relative;
}

.class-tile:not(:last-child)::after {
  content: "";
  background: rgba(0, 0, 0, 0.1);
  height: 2px;
  width: 35px;
  position: absolute;
  bottom: 0;
  left: 50%;
  border-radius: 50%;
  transform: translateX(-50%);
}

.dashboard-schedule td.no-classes {
  background-color: rgba(0, 0, 0, 0.1);
}

.dashboard-schedule .col-today {
  background-color: rgba(0, 0, 0, 0.829);
  color: white;
}

.class-tile {
  border-radius: 10px;
  min-height: 3.2rem;
  width: 70%;
  margin: 0.35rem auto;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  /* Behind the navbar */
  padding: 48px 0 0;
  /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: black;
  text-shadow: 1px 1px whitesmoke;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #d32f2f;
  font-weight: bold;
}

.sidebar .nav-link:hover {
  color: #d32f2f;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

footer {
  position: absolute;
  bottom: -100px;
  left: 40%;
}